@import "~@ng-select/ng-select/themes/default.theme.css";
html,body{margin:0;padding:0}

.card {
    position: relative;
    display: -webkit-box;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
}

.card-body {
    flex: 1 1 auto;
    padding: 1.25rem;
}
.text-area-wrap {
  position: relative;
}

.error-message {
  color: red;
  font-size: 0.9em;
 
  top: 100%; /* Position the error message below the textarea */
  left: 0;
  width: 100%;
  /* margin-top: 10px; */
  /* Adjust these values as needed for better positioning */
}

.translate-button {
  margin-top: 20px; /* Add some space between the textarea and the button */
}

.error-message{
  color: red;
}
.outline-none{
  outline: none; /* Remove outline on focus */
  --focus-outline: none;
}
.translator-card {
    width: 100%;
    margin: 0px auto;
    margin-top: 15px;
    margin-bottom: 20px;
    /* box-shadow: var(--telekom-shadow-raised-standard); */
    border: 1px solid transparent;
  }

  .grid-row {
    display: grid;
    grid-template-columns: 1fr .02fr 1fr; /* Adjust column widths as needed */
    grid-gap: 20px;
  }
  .grid-row-language {
    display: grid;
    grid-template-columns: 0.4fr auto 0.1fr 0.4fr auto; /* Adjust column widths as needed */
    grid-gap: 20px;
  }
  .grid-row-table{
    display: grid;
    grid-template-columns: auto; /* Adjust column widths as needed */
    grid-gap: 20px;
    margin-top: 40px;
  }
  .grid-row-button{
    display: grid;
    grid-template-columns: 1fr; /* Adjust column widths as needed */
    grid-gap: 20px;
  }
  .language-switch{
    transform: rotate(90deg);
  }
  .language-switch:hover {
    color: #e20074;
    cursor: pointer;
  }
  .remove{
    cursor: pointer;
  }
  .download-button {
    background-color: #e20074;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-top: 10px; /* Add margin at the top */
    border-radius: 5px;
  }
  .preview {
    margin-left: 10px;
    color: blue; /* Sets the color of the link */
    text-decoration: underline; /* Underlines the link text */
    cursor: pointer; /* Changes cursor to pointer on hover */
  }


.preview:hover {
    color: darkblue; /* Changes color on hover */
}
  .download {
    margin-left: 10px;
  }

  .grid-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Ensure the container takes at least the full viewport height */
  }
  
  .header {
    /* Keep the header as is */
    grid-area: header;
    justify-self: start;
  }
  
  .content {
    /* Keep the content as is */
    box-sizing: border-box;
    align-self: center;
    width: 80%;
    background: var(--background);
    padding-left: var(--spacing-x);
    padding-right: var(--spacing-x);
    flex: 1 1 0%;
    margin-top: 3%;
  }
  
  .footer {
    /* Adjust footer styles */
    margin-top: auto; /* Push the footer to the bottom */
  }
  .centered-table {
    width: 70%;
    margin: 0 auto; /* This centers the table horizontally */
  }
  .scrollable-table {
    width: 100%;
    margin: 0 auto; /* Center the table horizontally */
    max-height: 400px; /* Set the desired fixed height */
    overflow-y: hidden; /* Prevent the whole table from scrolling */
  }
  
  .scrollable-table table {
    width: 100%; /* Ensure the table takes full width of its container */
    border-collapse: collapse; /* Optional: for better table appearance */
  }
  
  .scrollable-table thead {
    display: table;
    width: 100% /* Adjust for scrollbar width */
  }
  
  .scrollable-table tbody {
    display: block;
    max-height: 350px; /* Adjust the height as needed */
    overflow-y: auto; /* Enable vertical scrolling for tbody */
  }
  
  .scrollable-table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  
  .scrollable-table th, .scrollable-table td {
    width: 33.33%; /* Adjust based on the number of columns */
  }
  
  .magenta-icon {
    color: #e20074; /* Set the icon color to magenta */
    cursor: pointer; /* Change cursor to pointer */
  }
  
  .magenta-icon:hover {
    cursor: pointer; /* Ensure the cursor is a pointer on hover */
  }
  
  .error-msg {
    margin: 10px auto;
    padding: 15px;
    border-radius: 5px;
    color: #D8000C;
    background-color: #FFBABA;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 600px; /* Adjust as needed */
    position: relative;
  }

  .table-container {
    display: flex;
    justify-content: center;
  }
  .required {
    color: red;
  }
  .custom-upload-btn {
    background-color: #e20074;
    color: white;
    padding: 0.5rem;
    /* font-family: sans-serif; */
    border-radius: 0.3rem;
    cursor: pointer;
    /* margin-top: 1rem; */
  }
  
  #file-chosen {
    margin-left: 0.5rem;
    /* font-family: sans-serif; */
    display: inline-block;
    vertical-align: middle;
  }
  
  .clear-btn {
    margin-left: 1rem;
    background-color: red;
    color: white;
    border: none;
    padding: 0.3rem 0.5rem;
    border-radius: 0.3rem;
    cursor: pointer;
  }